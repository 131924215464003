<script setup>
    import {ref} from "vue";
    import {useI18n} from "vue-i18n";
    const {locale} = useI18n();
    const {t}      = useI18n();

    const props = defineProps({
        discountCodeInfo:Object,
    })

    const form = ref({
        senderName: '',
        senderEmail: '',
        recipientName: '',
        recipientEmail: '',
        message: '',
    });

    const isSubmitting    = ref(false);
    const error_message   = ref(false);
    const success_message = ref(false);
    async function submitForm() {
        if (!form.value.senderName ||
            !form.value.senderEmail ||

            !form.value.recipientName ||
            !form.value.recipientEmail ||

            !form.value.message) {
            error_message.value = t("support.message.addAllInfo");
            return;
        }
        isSubmitting.value=true;
        if (form.value.honeypot === undefined ||form.value.honeypot === '') {
            try {
                const response = await axios.post(route('rest.discount.code.send',{locale:locale.value}), {
                    ...form.value,
                });
                success_message.value = t("support.message.successMessage");
            } catch (error) {
                console.error(error);
                error_message.value = t("support.message.errorMessage1");
            } finally {
                isSubmitting.value = false;
            }

        } else {
            success_message.value = false;
            // Bot détecté, ignorer la soumission
            console.log("Bot !");
        }
    }

    function resetForm(){
        form.value.recipientName = '';
        form.value.recipientEmail = '';
        isSubmitting.value=false;
        success_message.value=false;
        error_message.value=false;
    }

</script>
<template>

    <div class="max-w-7xl mx-auto">
        <section
            v-if="!success_message"
            class="m-auto"
        >
            <!-- Champ Honeypot -->
            <div style="display:none;">
                <label for="honeypot">Ne pas remplir ce champ</label>
                <input type="text" id="honeypot" v-model="form.honeypot" />
            </div>
            <div class="w-full flex justify-between gap-2">
                <div class="mb-4">
                    <label for="senderName" class="block text-sm font-medium text-gray-700">{{$t('formSendDiscountCode.senderNameLabel')}}</label>
                    <input
                        type="text"
                        id="senderName"
                        v-model="form.senderName"
                        class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                                focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>
                <div class="mb-4">
                    <label for="senderEmail" class="block text-sm font-medium text-gray-700">{{$t('formSendDiscountCode.senderEmailLabel')}}</label>
                    <input
                        type="email"
                        id="senderEmail"
                        v-model="form.senderEmail"
                        class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                         focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>
            </div>
            <div class="w-full flex justify-between gap-2">
                <div class="mb-4">
                    <label for="recipientName" class="block text-sm font-medium text-gray-700">{{$t('formSendDiscountCode.recipientNameLabel')}}</label>
                    <input
                        type="text"
                        id="recipientName"
                        v-model="form.recipientName"
                        class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                                focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>
                <div class="mb-4">
                    <label for="recipientEmail" class="block text-sm font-medium text-gray-700">{{$t('formSendDiscountCode.recipientEmailLabel')}}</label>
                    <input
                        type="email"
                        id="recipientEmail"
                        v-model="form.recipientEmail"
                        class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                        focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>
            </div>

            <div class="mb-4">
                <label for="message" class="block text-sm font-medium text-gray-700">{{$t('formSendDiscountCode.messageLabel')}}</label>
                <textarea
                    id="message"
                    v-model="form.message"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none
                    focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    rows="2"
                    required
                ></textarea>
            </div>
            <div
                v-if="error_message"
                class="my-4 text-red-500"
            >
                {{error_message}}
            </div>
            <div class="text-center">
                <button
                    type="submit"
                    @click="submitForm"
                    :class="['px-3 py-2 w-28 lg:w-36 rounded-lg shadow-lg text-violet-50',
                                (!isSubmitting)?'bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600 hover:from-lime-500 hover:via-pink-500 hover:to-fuchsia-500':'bg-gray-300',
                                'opacity-75 text-sm']"
                    :didable="isSubmitting"
                >
                    {{$t('formSendDiscountCode.submitButton')}}
                </button>
            </div>
        </section>
        <section
            v-else
            class="w-full"
        >
            <div
                class="my-4 text-green-600"
            >
                {{success_message}}
            </div>
            <div class="text-center">
                <button
                    type="submit"
                    @click="resetForm"
                    :class="['px-3 py-2 w-48 rounded-lg shadow-lg text-violet-50',
                                (!isSubmitting)?'bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600 hover:from-lime-500 hover:via-pink-500 hover:to-fuchsia-500':'bg-gray-300',
                                'opacity-75 text-sm']"
                    :didable="isSubmitting"
                >
                    {{$t('formSendDiscountCode.otherEmailButton')}}
                </button>
            </div>
        </section>
    </div>

</template>