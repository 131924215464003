<template>
    <div>
        <Carousel :autoplay="3000" pauseAutoplayOnHover :wrap-around="true">
            <Slide v-for="photo in Photos" :key="photo">
                <div
                    class="relative w-8/12 cursor-pointer overflow-hidden
                            flex items-center rounded-lg xl:rounded-2xl"
                    @click="handleZoom(photo)"
                >
                    <ImgLoading
                        :AttributeImg="{
                                source:`/storage/items_images/${photo.file_name}-thumbnail.${photo.extension}`,
                                altText:photo.photo_info.alt,
                                titleText:photo.photo_info.title,
                                className:'w-full rounded-lg xl:rounded-2xl',
                                srcset: `/storage/items_images/${photo.file_name}-small.${photo.extension} 480w,
                                         /storage/items_images/${photo.file_name}-medium.${photo.extension} 800w,
                                         /storage/items_images/${photo.file_name}-large.${photo.extension} 1200w,
                                         /storage/items_images/${photo.file_name}-large.${photo.extension} 2400w`,
                                sizes: '(max-width: 100px) 100vw, (max-width: 50px) 50vw, 33vw',
                                width: '2400',  // Dimensions de l'image la plus grande
                                height: '800'
                                }"
                    />
                </div>
            </Slide>
            <template #addons>
                    <Navigation v-if="showNavigation"/>
                    <Pagination v-if="showPagination"/>
            </template>
        </Carousel>

        <photo-zoom-modal
            :show="open_zoom_modal"
            :photo="photo_selected"
            :itemDescription="itemDescription"
            :currentLocale="currentLocale"
            :max-width="'7xl'"
            @close="handleCloseZoom"
        />
    </div>
</template>
<script setup>

    import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
    import ImgLoading from "@/Components/ImgLoading.vue";
    import PhotoZoomModal from "@/Components/PhotoZoomModal.vue";
    import {ref} from "vue";
    import 'vue3-carousel/dist/carousel.css'
    import Preorder from "@/Components/Preorder.vue";

    const props = defineProps({
        Photos:{type:Array},
        currentLocale: { type: String },
        showNavigation:{type:Boolean,default: false},
        showPagination:{type:Boolean,default: true},
        itemDescription:{type:Object,required:false},
    });
    const emit = defineEmits(["emitSelectPhoto"]);
    const open_zoom_modal = ref(false);
    const photo_selected = ref({});

    /*



                               altText:translate(photo.photo_infos,'alt'),
                                titleText:translate(photo.photo_infos,'title'),


    function translate(text,target){

        const text_translate = text.find(descr => {
                        // Extraire la partie avant le _
                        const langPrefix = descr.lang.split('_')[0];
                        return langPrefix === props.currentLocale;
                    }) || {};

        return text_translate[target];

    }

    */
    function handleCloseZoom(){
        open_zoom_modal.value = false;
        photo_selected.value = {};
    }
    function handleZoom(e){
        photo_selected.value = e;
        open_zoom_modal.value = true;
    }
</script>
<style scoped>
    .square {
        position: relative;
        width: 100%;
        padding-bottom: 100%; /* Maintient un ratio d'aspect de 1:1 */
    }

    .square img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>