<script setup>
    import {ref} from "vue";
    import { setWithExpiry, getWithExpiry } from '../Cache/Cache.js';

    const social_links = ref([]);
    const cacheKey = 'social_links';
    const ttl = 5 * 60 * 1000; // 5 minutes en millisecondes
    async function getSocialLink() {{
        const cachedLinks = getWithExpiry(cacheKey);

        if (cachedLinks) {
            // Si les données sont en cache et valides (pas expirées)
            social_links.value = cachedLinks;
        } else {
            try {
                const {data} = await axios.get(route('social.ling.get'));
                social_links.value = data.data;
                // Stocke les données avec un TTL de 5 minutes
                setWithExpiry(cacheKey, data.data, ttl);
            } catch (error) {
                console.log(error);
            }
        }
    }}
    getSocialLink();
</script>
<template>
    <div class="w-full flex items-center justify-between">
        <template
            v-for="social_link in social_links"
        >
            <a :href="social_link.url">
                <img :src="'/'+social_link.img" :alt="social_link.img_alt" class="w-12" target="_blank"/>
            </a>
        </template>
    </div>
</template>