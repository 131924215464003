<script setup>
    import ShoppingCartFormEmail from "@/Components/ShoppingCartFormEmail.vue";
    import {ref} from "vue";
    const props = defineProps({
        discountCodeInfo:Object
    })
    const show_form_email = ref(false)

    function toogleFormMail(e){
        show_form_email.value = e;
        if (window._paq) {
            window._paq.push(['trackEvent', 'Panier', 'Email','afficher/masquer le formulaire email']);
        }
    }

</script>
<template>
    <div class="w-full border-2 border-green-700 text-lg p-4 rounded-lg my-4">
        <div class="text-green-700">
            {{$t('showDiscountCodeInfo.text11')}}  <span class="font-bold text-green-800">{{discountCodeInfo.code}}</span>
            {{$t('showDiscountCodeInfo.text12')}}
        </div>
        <div
            v-if="Number(discountCodeInfo.discount_value)>0"
            class="text-green-700"
        >
            <p>{{$t('showDiscountCodeInfo.text2')}} {{ Number(discountCodeInfo.discount_value) }} <template v-if="discountCodeInfo.type_discount==='percent'">%</template><template v-else> €</template></p>
        </div>
        <div
            v-if="discountCodeInfo.shipping_free"
            class="text-green-700"
        >
            <p>
                {{$t('showDiscountCodeInfo.text3')}}
            </p>
        </div>
        <div class="w-full my-4 text-gray-600 text-center">
            <p>{{$t('showDiscountCodeInfo.text4')}}</p>
            <div
                v-if="!show_form_email"
                class="w-full p-2 text-center cursor-pointer"
                @click="toogleFormMail(true)"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mx-auto fade-animation">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                </svg>
            </div>
            <div
                v-else
                class="w-full p-2 text-center cursor-pointer"
                 @click="toogleFormMail(false)"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mx-auto fade-animation">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
            </svg>
            </div>
        </div>
        <shopping-cart-form-email
            v-if="show_form_email"
            :discountCodeInfo="discountCodeInfo"
        />
    </div>
</template>
<style>
    .fade-animation {
        animation: fadeInOut 2s infinite;
    }

    @keyframes fadeInOut {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>