<script setup>

    import {computed, ref} from "vue";
    import { router,Link } from '@inertiajs/vue3'
    import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
    import LangageSelector from "@/Components/LangageSelector.vue";
    import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import NavLink from "@/Components/NavLink.vue";
    import { useI18n } from 'vue-i18n';
    import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";
    const { locale } = useI18n();

    const props = defineProps({
        auth: { type: Object, required: true }
    });

    const showingNavigationDropdown = ref(false);

    const switchToTeam = (team) => {
        router.put(route('current-team.update'), {
            team_id: team.id,
        }, {
            preserveState: false,
        });
    };
    const emit = defineEmits(['emitToogleCart'])
    function handleOpenCart() {
        emit('emitToogleCart');
    }
    const logout = () => {
        console.log('logout');
        router.post(route('logout'));
    };
</script>
<template>
    <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
        <div class="mx-auto px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 justify-between">
                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
                <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <NavLink
                            :active="route().current('home')"
                            :href="route('home',{locale:locale})">
                            {{ $t('navBar.homePage') }}
                        </NavLink>
                        <NavLink
                            :active="route().current('web.item.index')"
                            :href="route('web.item.index', {
                                        locale: locale,
                                        slugProductsPage: $t('navBar.slugProductsPage')
                                    })"
                        >
                            {{ $t('navBar.productsPage') }}
                        </NavLink>
                        <NavLink
                            :active="route().current('item.type.sport')"
                            :href="route('item.type.sport', {
                                                locale: locale,
                                                slugProductsTypeSportPage: $t('navBar.slugProductsTypeSportPage')
                                            })"
                        >
                            {{ $t('navBar.productsTypeSportPage') }}
                        </NavLink>
                        <NavLink
                            :active="route().current('item.type.classic')"
                            :href="route('item.type.classic', {
                                                locale: locale,
                                                slugProductsTypeClassicPage: $t('navBar.slugProductsTypeClassicPage')
                                            })"
                        >
                            {{ $t('navBar.productsTypeClassicPage') }}
                        </NavLink>
                        <NavLink
                            :active="route().current('item.type.fashion')"
                            :href="route('item.type.fashion', {
                                                locale: locale,
                                                slugProductsTypeFashionPage: $t('navBar.slugProductsTypeFashionPage')
                                            })"
                        >
                            {{ $t('navBar.productsTypeFashionPage') }}
                        </NavLink>
                        <NavLink
                            :active="route().current('item.type.custom.made.lenses')"
                            :href="route('item.type.custom.made.lenses', {
                                                locale: locale,
                                                slugProductsTypeCMLPage: $t('navBar.slugCustomMadeLensesPage'),
                                            })"
                        >
                            {{ $t('navBar.productsCustomMadeLensePage') }}
                        </NavLink>
<!--                        <NavLink
                            :active="route().current('privacy.policy')"
                            :href="route('terms.and.conditions',{locale:locale})"
                        >
                            {{ $t('navBar.termsAndConditions') }}
                        </NavLink>-->
                    </div>
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                        type="button"
                        class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="handleOpenCart"
                    >
                        <span class="absolute -inset-1.5" />
                        <span class="sr-only">View cart</span>
                        <font-awesome-icon :icon="faCartShopping" />
                    </button>
                    <!-- lang selector -->
                    <langage-selector />
                    <!-- Profile dropdown -->
                    <Menu as="div" class="relative ml-3">
                        <div>
                            <MenuButton class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="absolute -inset-1.5" />
                                <span class="sr-only">Open user menu</span>
                                <img class="h-8 w-8 rounded-full" width="100" height="100" src="/images/logo/logo-xs.webp" :alt="$t('logo.alt')" />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white
                                            py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <MenuItem
                                    v-if="!auth.user"
                                >
                                    <Link
                                        :href="route('login')"
                                        :class="['block px-4 py-2 text-sm text-gray-700']">
                                        {{$t('navBar.userConnexion')}}
                                    </Link>
                                </MenuItem>
                                <MenuItem
                                    v-if="!auth.user"
                                >
                                    <Link
                                        :href="route('register')"
                                        :class="['block px-4 py-2 text-sm text-gray-700']">
                                        {{$t('navBar.userRegister')}}
                                    </Link>
                                </MenuItem>
                                <template
                                    v-if="auth.user"
                                >
                                    <MenuItem>
                                        <Link
                                            :href="route('profile.show')"
                                            :class="['block px-4 py-2 text-sm text-gray-700']">
                                            {{$t('navBar.myAccount')}}
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            :href="route('local.order.index')"
                                            :class="['block px-4 py-2 text-sm text-gray-700']">
                                            {{$t('navBar.userOrders')}}
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            :href="route('user.info.index')"
                                            :class="['block px-4 py-2 text-sm text-gray-700']">
                                            {{$t('navBar.userInfo')}}
                                        </Link>
                                    </MenuItem>

                                    <MenuItem>
                                        <Link
                                            :href="route('address.index')"
                                            :class="['block px-4 py-2 text-sm text-gray-700']">
                                            {{$t('navBar.userAddress')}}
                                        </Link>
                                    </MenuItem>
                                    <form method="POST" @submit.prevent="logout">
                                        <ResponsiveNavLink as="button">
                                            {{$t('navBar.logout')}}
                                        </ResponsiveNavLink>
                                    </form>
                                </template>
                                <!--
                                <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Your Profile</a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign out</a>
                                </MenuItem>
                                -->
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>
        <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 pb-4 pt-2">
                <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
                <DisclosureButton as="a"
                                  :href="route('home',{locale:locale})"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('landing')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.homePage') }}
                </DisclosureButton>
                <DisclosureButton as="a"
                                  :href="route('web.item.index', {
                                                locale: locale,
                                                slugProductsPage: $t('navBar.slugProductsPage')
                                              })"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('web.item.index')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.productsPage') }}
                </DisclosureButton>
                <DisclosureButton as="a"
                                  :href="route('item.type.sport', {
                                                locale: locale,
                                                slugProductsTypeSportPage: $t('navBar.slugProductsTypeSportPage'),
                                            })"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('item.type.sport')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.productsTypeSportPage') }}
                </DisclosureButton>
                <DisclosureButton as="a"
                                  :href="route('item.type.classic', {
                                                locale: locale,
                                                slugProductsTypeClassicPage: $t('navBar.slugProductsTypeClassicPage'),
                                            })"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('item.type.classic')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.productsTypeClassicPage') }}
                </DisclosureButton>
                <DisclosureButton as="a"
                                  :href="route('item.type.fashion', {
                                                locale: locale,
                                                slugProductsTypeFashionPage: $t('navBar.slugProductsTypeFashionPage'),
                                            })"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('item.type.fashion')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.productsTypeFashionPage') }}
                </DisclosureButton>
                <DisclosureButton as="a"
                                  :href="route('item.type.custom.made.lenses', {
                                                locale: locale,
                                                slugProductsTypeCMLPage: $t('navBar.slugCustomMadeLensesPage'),
                                            })"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('item.type.custom.made.lenses')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.productsCustomMadeLensePage') }}
                </DisclosureButton>
                <!--
                <DisclosureButton as="a"
                                  :href="route('terms.and.conditions',{locale:locale})"
                                  :class="['block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                                  route().current('terms.and.conditions')?'border-indigo-500 bg-indigo-50 text-indigo-700':'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                                  ]"
                >
                    {{ $t('navBar.termsAndConditions') }}
                </DisclosureButton>
                -->
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>