<script setup>

    import Modal from "@/Components/Modal.vue";
    import {XMarkIcon} from "@heroicons/vue/20/solid/index.js";
    import ModeShoppingCartForModal from "@/Components/ModeShoppingCartForModal.vue";

    const props = defineProps({
        auth:{type:Object},
        show: {type: Boolean, default: false},
        closeable: {type: Boolean, default: true},
        maxWidth: {type: String, default: '2xl'},
        shipping_rate_country:{type:Object,required:false},
    });
    const emit = defineEmits(['close']);

    const close = () => {
        emit('close');
    };

</script>
<template>
    <Modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        @close="close"
    >
        <div class="p-2 flex justify-between items-center">
            <div class="w-full">
                <h1 id="summary-heading"
                    class="custom-text-gradient text-2xl lg:text-3xl font-bold"
                >{{ $t('shoppingCart.title') }}</h1>
            </div>
            <div class="w-10">
                <button
                    @click="close"
                    type="button"
                    class="inline-flex p-2 text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Remove</span>
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
        <div class="p-2">
            <mode-shopping-cart-for-modal
                :auth="auth"
                :shipping_rate_country="shipping_rate_country"
                @emitToogleMode="is_shopping_cart_mode=!is_shopping_cart_mode"
            />
        </div>
    </Modal>
</template>
