<template>
    <Modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        @close="close"
    >
        <div class="bg-white w-7/12 mx-auto">
            <ImgLoading
                @click="close"
                v-if="photo && Object.keys(photo).length > 0"
                :AttributeImg="{
                            source:`/storage/items_images/${photo.file_name}-thumbnail.${photo.extension}`,
                            altText:photo.photo_info.alt,
                            titleText:photo.photo_info.title,
                            className:'w-full object-cover',
                            srcset: `/storage/items_images/${photo.file_name}-small.${photo.extension} 480w,
                                     /storage/items_images/${photo.file_name}-medium.${photo.extension} 800w,
                                     /storage/items_images/${photo.file_name}-large.${photo.extension} 1200w`,
                            sizes: '(max-width: 100px) 100vw, (max-width: 50px) 50vw, 33vw',
                            width: '1200',  // Dimensions de l'image la plus grande
                            height: '800'
                            }"
            />
        </div>
        <div
            v-if="show"
            class="px-6 py-4 bg-gray-50">
            <h2 class="text-sm md:text-lg font-medium">
                <span class="custom-text-gradient">
                    {{itemDescription.designation}}
                </span>
            </h2>
            <h3 class="text-sm md:text-lg font-medium text-gray-800">
                {{photo.photo_info.title}}
            </h3>
            <div class="mt-4 text-sm text-gray-600">
                {{photo.photo_info.alt}}
            </div>
        </div>
    </Modal>
</template>
<script setup>

    import Modal from './Modal.vue';
    import ImgLoading from "@/Components/ImgLoading.vue";

    const emit = defineEmits(['close']);
    const props = defineProps({
                                itemDescription:{type:Object,required:false},
                                show: {type: Boolean, default: false},
                                maxWidth: {type: String, default: '2xl'},
                                closeable: {type: Boolean, default: true},
                                photo:{type:Object,required:false},
                                currentLocale: { type: String }
                            });

    const close = () => {
        emit('close');
    };
    /*function translate(text,target){
        if(props.show) {
            const text_translate = text.find(descr => {
                // Extraire la partie avant le _
                const langPrefix = descr.lang.split('_')[0];
                return langPrefix === props.currentLocale;
            }) || {};

            return text_translate[target];
        }
    }*/

</script>
