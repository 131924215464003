<script setup>

    import { QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
    import {computed, ref} from "vue";
    import {useI18n} from "vue-i18n";
    import ImgLoading from "@/Components/ImgLoading.vue";
    import Loading from "@/Components/Loading.vue";
    import Checkout from "@/Pages/Checkout/Checkout.vue";
    import {Link} from "@inertiajs/vue3";
    import ShowAddressFr from "@/Components/ShowAddressFr.vue";
    import ShoppingCartLineForModal from "@/Components/ShoppingCartLineForModal.vue";

    import InputLabel from "@/Components/InputLabel.vue";
    import TextInput from "@/Components/TextInput.vue";
    import ShowDiscountCodeInfo from "@/Components/ShowDiscountCodeInfo.vue";
    import SocialLinksHorizontal from "@/Components/SocialLinksHorizontal.vue";

    const props = defineProps({
        auth:{type:Object},
        shipping_rate_country:{type:Object,required:false},
    });

    const { locale } = useI18n();
    const { t } = useI18n();

    const emit  = defineEmits(["close","emitToogleMode"]);

    const local_order_id = ref(null);

    const loading_discount_code         = ref(false);
    const loading                       = ref(false);
    const cart                          = ref([]);
    const total_weight                  = ref(0);
    const shipping_rate                 = ref([]);
    const show_detail_shipping          = ref(false);
    const show_detail_taxes             = ref(false);
    const isBillingAndShippingDifferent = ref(false);
    const shipping_address              = ref({});
    const shipping_address_valid        = ref(false);
    const billing_address               = ref({});
    const billing_address_valid         = ref(false);
    const btn_disabled                  = ref(false);
    const discount_code                 = ref(null);
    const discount_code_valid           = ref(true);
    const discount_code_info            = ref({});

    const totalAmount      = computed(() => {
        return cart.value.reduce((sum, item) => {
            return sum + (item.quantity * parseFloat(item.price));
        }, 0);
    });
    const totalReductionAmount = computed(() => {
        let total = cart.value.reduce((sum, item) => {
            return sum + (item.quantity * parseFloat(item.price));
        }, 0);

        let reduction = 0;

        if (discount_code_info.value && discount_code_info.value.is_active) {
            const discount = discount_code_info.value;
            if (discount.type_discount === 'percent') {
                // Calculer la réduction en pourcentage
                const discountValue = parseFloat(discount.discount_value);
                reduction = total * (discountValue / 100);
            } else if (discount.type_discount === 'fixed') {
                // Calculer la réduction fixe
                reduction = parseFloat(discount.discount_value);
            }
        }

        return reduction > 0 ? reduction : 0; // Assurer que le total ne soit pas négatif
    });

    const totalWeight = computed(() => {
        return cart.value.reduce((sum, item) => {
            return sum + (item.quantity * parseFloat(item.weight));
        }, 0);
    });
    const totalTaxes  = computed(() => {
        if (cart.value.length === 0) {
            return [];
        }

        const taxMap = {};
        let minTax   = null;
        const items  = cart.value ? cart.value : [];
        let totalReduction = 0;

        // Calcul de la réduction totale
        if (discount_code_info.value && discount_code_info.value.is_active) {
            const discount = discount_code_info.value;
            const totalCartValue = items.reduce((sum, item) => sum + (item.quantity * parseFloat(item.price)), 0);

            if (discount.type_discount === 'percent') {
                totalReduction = (totalCartValue * parseFloat(discount.discount_value)) / 100;
            } else if (discount.type_discount === 'fixed') {
                totalReduction = parseFloat(discount.discount_value);
            }
        }

        items.forEach(item => {
            const priceHT = item.price;
            const quantity = item.quantity;
            const priceAfterDiscount = priceHT - (totalReduction / items.length); // Appliquer une réduction moyenne par article

            item.taxes.forEach(tax => {
                const taxKey = tax.id;
                const taxAmount = (priceAfterDiscount * quantity * tax.value) / 100;

                if (!minTax || tax.value < minTax.value) {
                    minTax = { ...tax, amount: taxAmount };
                }

                if (taxMap[taxKey]) {
                    taxMap[taxKey].value += taxAmount;
                } else {
                    taxMap[taxKey] = {
                        ...tax,
                        value: taxAmount
                    };
                }
            });
        });

        // Utilisation de minTax pour le calcul des frais de port
        if (shipping_rate.value && minTax &&
            (
                !discount_code_info.value || // Vérifie si l'objet discount_code_info est vide ou n'existe pas
                discount_code_info.value.shipping_free === false // Vérifie que shipping_free n'est pas false
            )) {
            const shippingAmount = parseFloat(shipping_rate.value.rate);
            const shippingTaxAmount = (shippingAmount * minTax.value) / 100;

            const shippingTaxKey = "shipping_min_tax";
            if (taxMap[shippingTaxKey]) {
                taxMap[shippingTaxKey].value += shippingTaxAmount;
            } else {
                taxMap[shippingTaxKey] = {
                    id: shippingTaxKey,
                    display_name: `${minTax.display_name} ${t('shoppingCart.shippingTaxe')}`,
                    value: shippingTaxAmount
                };
            }
        }
        // Arrondir les valeurs des taxes à deux chiffres après la virgule
        Object.values(taxMap).forEach(tax => {
            tax.value = parseFloat(tax.value.toFixed(2));
        });

        return Object.values(taxMap);
    });

    const totalTaxesAmount = computed(() => {
        return totalTaxes.value.reduce((sum, tax) => sum + tax.value, 0).toFixed(2);
    });
    const formattedText    = computed(() => {
        return t('shoppingCart.emptyText').replace(/\n/g, '<br>');
    });
    const totalWithTaxes   = computed(() => {
        let tt = (parseFloat(totalAmount.value) - parseFloat(totalReductionAmount.value)) + parseFloat(totalTaxesAmount.value);

        if (!discount_code_info.value || !discount_code_info.value.shipping_free) {
            tt = tt+ parseFloat(shipping_rate.value.rate);
        }

        return parseFloat(tt.toFixed(2));
    });

    function tootgleMode(){
        emit('emitToogleMode');
    }
    function handleClose(){
        emit('close');
    }
    async function getItemsCart(){
        loading.value = true;
        try {
            const {data} = await axios.get(route('cart.show', { locale: locale.value }));
            cart.value               = data.cart.items;
            local_order_id.value     = data.cart.local_order_id;
            discount_code_info.value = data.cart.discount_code_info;
            if(data.cart.discount_code_info){
                discount_code.value      = data.cart.discount_code_info.code;
            }
            shipping_rate.value  = data.shippingRate;
            loading.value        = false;
        }catch (e) {
            console.error();
        }

    }
    function UpdateCart(e){
        cart.value           = e.cart.items;
        local_order_id.value = e.cart.local_order_id;
        shipping_rate.value  = e.shippingRate;
    }
    async function updateItemCart(e){
        try {
            const {data} = await axios.put(route('cart.update.item',{locale:locale.value}), {
                id: e.id,
                locale: locale.value,
                qt:e.qt,
            });
            cart.value = data.cart.items;
            shipping_rate.value=data.shippingRate;
        }catch (e) {
            console.error();
        }
    }
    async function removeFromCart(e) {
        try {
            const {data} = await axios.delete(route('cart.remove.item',{locale:locale.value,id:e}));
            cart.value = data.cart.items;
            shipping_rate.value=data.shippingRate;
        }catch (e) {
            console.error(e);
        }
    }
    async function getShippingAddress(){
        loading.value = true;
        try {
            const {data} = await axios.get(route('user.info.get.address'));
            shipping_address.value        = data.shipping_address;
            billing_address.value         = data.billing_address;
            isBillingAndShippingDifferent.value = data.isBillingAndShippingDifferent;
            loading.value = false;
        }catch (e) {
            console.error(e);
        }
    }
    /* async function createLocalOrder(){
         loading.value = true;
         try {
             const {data} = await axios.post(route('order.create'));
             local_order_id.value = data.cart.local_order_id;
             //await createOrder();
         }catch (error) {
             console.error(error);
         }
     }*/
    async function createOrder(){

        btn_disabled.value = true;
        try {
            const {data} = await axios.post(route('remote.order.create'),{
                locale:locale.value,
            });

            window.location.href = route('order.summary.index',{id:data});
            loading.value = false;
        } catch (error) {
            console.error(error);

        }
    };
    async function applyDiscount(){

        loading_discount_code.value = true;
        discount_code_info.value = {};
        if(discount_code.value===''){
            discount_code_valid.value = false;
        }else{
            try{
                const {data} = await axios.post(route('discount.code.get',{locale:locale.value}),
                    {discount_code:discount_code.value}
                );

                discount_code_valid.value   = data.success;
                discount_code_info.value    = data.discount_code_info;
                getItemsCart()
                loading_discount_code.value = false;
            }catch (e) {
                console.error(e);
            }
        }

    };
    const hasBillingAddress  = computed(() => {
        if (billing_address.value) {
            if (Object.keys(billing_address.value).length > 0) {
                if(isBillingAndShippingDifferent){
                    billing_address_valid.value =  isAddressComplete(billing_address.value);
                }else{
                    billing_address_valid.value = true;
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const hasShippingAddress = computed(() => {
        if (shipping_address.value) {
            if (Object.keys(shipping_address.value).length > 0) {
                shipping_address_valid.value =  isAddressComplete(shipping_address.value);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    function isAddressComplete(address) {
        console.log(props.shipping_rate_country);
        const requiredFieldsPresent = address.country && address.full_name && address.street_l1 && address.town && address.postal_code && address.iso_3166_2;
        // Extraire tous les country_id de props.shipping_rate_country.data
        const countryIds = props.shipping_rate_country.data.map(item => item.country_id);
        const countryIdValid = countryIds.includes(address.country_id);

        return requiredFieldsPresent && countryIdValid;
    }
    const isNonEmptyObject = (value) => {
        return typeof value === 'object' && value !== null && Object.keys(value).length > 0;
    }
    /*const hasCompleteAddress = computed(() => {
        if (props.isBillingAndShippingDifferent) {
            // Vérifier les deux adresses
            return isAddressComplete(props.billing_address) && isAddressComplete(props.shipping_address);
        } else {
            // Ne vérifier que l'adresse de livraison
            return isAddressComplete(props.shipping_address);
        }
    });*/

    async function init(){
        await getItemsCart();
        if(props.auth.user){
            await getShippingAddress();
        }
    }
    init();

</script>
<template>
    <div class="bg-white">
        <div class="w-full mt-2
                    lg:mt-6 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12
                    xl:gap-x-16
                    ">
            <!-- cart -->
            <template
                v-if="!loading">
                <!-- items list -->
                <template v-if="cart && cart.length > 0">
                    <!-- items -->
                    <section
                        aria-labelledby="cart-heading"
                        class="lg:col-span-7"
                    >
                        <ul role="list" class="divide-y divide-gray-200 border-b border-t border-gray-200">
                            <li v-for="(product, productIdx) in cart" :key="product.id" class="flex py-6 sm:py-10">
                                <shopping-cart-line-for-modal
                                    :product="product"
                                    :productIdx="productIdx"
                                    @emitRemove="removeFromCart"
                                    @emitUpdateQuantity="updateItemCart"
                                />
                            </li>
                        </ul>
                    </section>
                    <!-- Order summary -->
                    <section aria-labelledby="summary-heading" class="lg:col-span-5 ">
                        <dl>
                            <div class="w-full relative">
                                <InputLabel for="discount_code" :value="$t('shoppingCartForModal.discountCode')"
                                            class="text-gray-700"/>
                                <div class="relative">
                                    <TextInput
                                        id="discount_code"
                                        v-model="discount_code"
                                        type="text"
                                        class="mt-1 block w-full pr-12"
                                    />
                                    <button
                                        type="button"
                                        class="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2
                                      bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600
                                      hover:from-lime-500 hover:via-pink-500 hover:to-fuchsia-500
                                      text-white rounded-r hover:bg-blue-600"
                                        @click="applyDiscount"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            class="size-6"
                                            :class="{ 'animate-spin': loading_discount_code }"
                                        >
                                            <path fill-rule="evenodd" d="M11.99 2.243a4.49 4.49 0 0 0-3.398 1.55 4.49 4.49 0 0 0-3.497 1.306 4.491 4.491 0 0 0-1.307 3.498 4.491 4.491 0 0 0-1.548 3.397c0 1.357.6 2.573 1.548 3.397a4.491 4.491 0 0 0 1.307 3.498 4.49 4.49 0 0 0 3.498 1.307 4.49 4.49 0 0 0 3.397 1.549 4.49 4.49 0 0 0 3.397-1.549 4.49 4.49 0 0 0 3.497-1.307 4.491 4.491 0 0 0 1.306-3.497 4.491 4.491 0 0 0 1.55-3.398c0-1.357-.601-2.573-1.549-3.397a4.491 4.491 0 0 0-1.307-3.498 4.49 4.49 0 0 0-3.498-1.307 4.49 4.49 0 0 0-3.396-1.549Zm3.53 7.28a.75.75 0 0 0-1.06-1.06l-6 6a.75.75 0 1 0 1.06 1.06l6-6Zm-5.78-.905a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm4.5 4.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z" clip-rule="evenodd" />
                                        </svg>

                                    </button>
                                </div>
                            </div>
                            <template v-if="!discount_code_valid">
                                <div class="w-full mt-4 border-red-600 border-2 rounded p-2 text-red-600">
                                    <p class="text-center font-bold">{{$t('shoppingCartForModal.hoNo')}}</p>
                                </div>
                            </template>
                            <show-discount-code-info
                                v-if="isNonEmptyObject(discount_code_info)"
                                :discountCodeInfo="discount_code_info"
                            />
                            <div class="w-full mt-4 bg-violet-400 rounded p-2 text-white space-y-3">
                                <div class="mt-4 bg-violet-400 rounded p-2">
                                    <social-links-horizontal />
                                </div>
                                <p class="text-justify">
                                    {{$t('shoppingCartForModal.subscribe')}}
                                </p>
                            </div>
                        </dl>
                        <dl class="mt-10 rounded-lg bg-gray-100 px-4 py-6 sm:p-6 lg:mt-4 lg:p-8 space-y-4">
                            <div
                                v-if="discount_code_info && Number(discount_code_info.discount_value)>0"
                                class="flex items-center justify-between">
                                <dt class="text-sm text-gray-600">Base HT</dt>
                                <dd class="text-sm font-medium text-gray-900">{{totalAmount.toFixed(2)}}</dd>
                            </div>
                            <div
                                v-if="discount_code_info"
                                class="flex items-center justify-between"
                            >
                                <dt class="w-full flex justify-start items-center text-sm text-gray-600">
                                    <div class="w-full">
                                        <p> {{$t('shoppingCartForModal.discountCode')}} : <span class="font-bold">{{ discount_code_info.code }}</span></p>
                                    </div>
                                    <div class="w-14">
                                        <template v-if="Number(discount_code_info.discount_value)>0">
                                            <p>
                                            - {{Number(discount_code_info.discount_value).toFixed(0)}} <template v-if="discount_code_info.type_discount==='percent'">%</template>
                                            <template v-if="discount_code_info.type_discount==='fixe'">€</template>
                                            </p>
                                        </template>
                                    </div>
                                </dt>
                                <dd class="w-24 text-sm font-medium text-gray-900 text-right">
                                    <template v-if="Number(discount_code_info.discount_value)>0">
                                        - {{totalReductionAmount.toFixed(2)}}
                                    </template>
                                </dd>
                            </div>
                            <div class="flex items-center justify-between">
                                <dt class="text-sm text-gray-600 font-bold">
                                    {{ $t('shoppingCart.orderValueNoTaxe') }}
                                </dt>
                                <dd class="text-sm font-medium text-gray-900">{{(totalAmount-totalReductionAmount).toFixed(2)}}</dd>
                            </div>
                            <!-- shipping -->
                            <div
                                class="border-t border-gray-300 pt-4 cursor-pointer"
                                @click="show_detail_shipping=!show_detail_shipping"
                            >
                                <div
                                    v-if="false && show_detail_shipping"
                                    class="text-gray-300 text-sm">
                                    <div class="flex items-center justify-between ">
                                        <dt>
                                            {{ $t('shoppingCart.shippingEstimateNoTaxe') }}
                                        </dt>
                                        <dd class=" font-medium">{{shipping_rate.rate}}</dd>
                                    </div>

                                    <div
                                        v-for="detailtaxe in shipping_rate.taxes"
                                        class="flex items-center justify-between "
                                    >
                                        <dt>
                                            {{ $t('shoppingCart.datailTaxe') }} {{detailtaxe.value}}%
                                        </dt>
                                    </div>
                                    <div class="flex items-center justify-between ">
                                        <dt>
                                            {{ $t('shoppingCart.taxesValue') }}
                                        </dt>
                                        <dd class=" font-medium">{{shipping_rate.taxes_value}}</dd>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between ">
                                    <dt class="flex items-center text-sm text-gray-600">
                                        <div>
                                            <span>{{ $t('shoppingCart.shippingEstimateNoTaxe') }}</span>
                                            <template v-if="discount_code_info && discount_code_info.shipping_free">
                                                <span class="text-violet-900 font-bold">
                                                    (Offert)
                                                </span>

                                            </template>
                                        </div>
                                        <div class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                            <span class="sr-only">{{ $t('shoppingCart.shippingEstimateNoTaxe') }}</span>
                                            <QuestionMarkCircleIcon v-if="false" class="h-5 w-5" aria-hidden="true" />
                                        </div>
                                    </dt>
                                    <dd
                                        :class="['text-sm font-medium text-gray-900',
                                        (discount_code_info && discount_code_info.shipping_free)?'line-through':''
                                        ]"
                                    >
                                        {{shipping_rate.rate}}
                                    </dd>
                                </div>
                            </div>
                            <!-- Détails des taxes -->
                            <div class="border-t border-gray-400 pt-4 cursor-pointer" @click="show_detail_taxes = !show_detail_taxes">
                                <div v-if="show_detail_taxes" class="text-gray-400 text-sm">
                                    <!-- Détails des taxes du panier -->
                                    <div v-for="detailtaxe in totalTaxes" class="flex items-center justify-between">
                                        <dt>{{ detailtaxe.display_name }}</dt>
                                        <dd class="font-medium">{{ detailtaxe.value.toFixed(2) }}</dd>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between">
                                    <dt class="flex items-center text-sm text-gray-600">
                                        <span>{{ $t('shoppingCart.taxEstimate') }}</span>
                                        <div class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                            <span class="sr-only">{{ $t('shoppingCart.taxEstimate') }}</span>
                                            <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                                        </div>
                                    </dt>
                                    <dd class="text-sm font-medium text-gray-900">{{totalTaxesAmount}}</dd>
                                </div>
                            </div>
                            <!-- total order -->
                            <div class="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt class="text-base font-medium text-gray-900">{{ $t('shoppingCart.total') }}</dt>
                                <dd class="text-base font-medium text-gray-900">{{ totalWithTaxes.toLocaleString(2) }}</dd>
                            </div>
                        </dl>
                        <dl v-if="auth.user && hasShippingAddress"
                            :class="['bg-white mt-6 rounded-lg p-2 lg:p-8  border-2',
                                    (!shipping_address_valid)?'border-red-200 text-red-500':'border-gray-200'
                                    ]"
                        >
                            <show-address-fr
                                :shippingAddress="shipping_address"
                                :targetAddress="isBillingAndShippingDifferent ? $t('shoppingCart.shippingAddress') : $t('shoppingCart.sameAddress')"
                            />
                            <div v-if="!shipping_address_valid">
                                {{$t('userInfo.invalidAddress')}}
                            </div>
                        </dl>
                        <dl v-if="auth.user && hasBillingAddress && isBillingAndShippingDifferent"
                            :class="['bg-white mt-6 rounded-lg p-2 lg:p-8  border-2',
                                    (!billing_address_valid)?'border-red-600 text-red-500':'border-gray-200'
                                    ]"
                        >
                            <show-address-fr
                                :shippingAddress="billing_address"
                                :targetAddress="$t('shoppingCart.billingAddress')"
                            />
                            <div v-if="!billing_address_valid">
                                {{$t('userInfo.invalidAddress')}}
                            </div>
                        </dl>
                        <dl v-if="auth.user && hasShippingAddress"
                            class="bg-white my-6 text-right"
                        >
                            <button
                                :class="['px-3 py-2 w-full rounded-lg shadow-lg text-violet-50',
                                        (btn_disabled)?'bg-gray-300':'bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600 hover:from-lime-500 hover:via-pink-500 hover:to-fuchsia-500',
                                        'opacity-75 text-sm']"
                                :disabled="btn_disabled"
                                @click="createOrder"
                            >
                                <span v-if="btn_disabled" class="w-full flex justify-center">
                                    <loading class="w-5 h-5" />
                                </span>
                                <span v-else>
                                      {{$t('checkout.btnCheckout')}}
                                </span>
                            </button>
                        </dl>
                        <dl v-if="!auth.user" class="mt-4 w-full text-center">
                            <Link
                                :href="route('register')"
                                :class="['px-3 py-2 w-full block rounded-lg shadow-lg text-violet-50',
                                    'bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600',
                                    'hover:from-lime-500 hover:via-pink-500 hover:to-fuchsia-500',
                                    'opacity-75 text-sm']">
                                {{$t('authPage.btnValidCart')}}
                            </Link>
                        </dl>
                    </section>
                    <!--
                    <div class="mt-6">
                        <button type="submit"
                                class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3
                        text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
                        focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">{{ $t('shoppingCart.btCheckout') }}</button>
                    </div>
                    -->
                </template>
                <!-- empty item -->
                <template v-else>
                    <section
                        aria-labelledby="cart-heading"
                        class="lg:col-span-7"
                    >
                        <h2 id="cart-heading" class="w-full text-2xl text-center">{{$t('shoppingCart.emptySubTitle')}}</h2>
                        <div class="empty-cart">
                            <div class="w-1/2 mx-auto">
                                <ImgLoading
                                    :AttributeImg="{
                                            source:`/images/logo/empty-dog-cart.webp`,
                                            altText:$t('shoppingCart.emptyImgAlt'),
                                            titleText:$t('shoppingCart.emptyImgTitle'),
                                            className:'w-full rounded-lg xl:rounded-2xl',
                                            srcset: `/images/logo/empty-dog-cart-sm.webp 480w,
                                                     /images/logo/empty-dog-cart-md.webp 800w,
                                                     /images/logo/empty-dog-cart-lg.webp 1200w,
                                                     /images/logo/empty-dog-cart-xl.webp 3600w`,
                                            sizes: `(max-width: 480px) 100vw,
                                                        (max-width: 800px) 50vw,
                                                        (max-width: 1200px) 33vw,
                                                        (max-width: 2400px) 25vw,
                                                        (max-width: 3200px) 20vw,
                                                        16vw`,
                                            width: '3600',  // Dimensions de l'image la plus grande
                                            height: '3600'
                                            }"
                                />
                            </div>
                            <p class="text-justify p-4" v-html="formattedText" />
                        </div>
                    </section>
                    <section
                        aria-labelledby="summary-heading"
                        class="w-full h-full lg:col-span-5 flex items-end"
                    >
                        <div class="w-1/2 mx-auto">
                            <ImgLoading
                                :AttributeImg="{
                                source:`/images/logo/empty-cart.webp`,
                                altText:$t('shoppingCart.emptyImgAlt'),
                                titleText:$t('shoppingCart.emptyImgTitle'),
                                className:'w-full rounded-lg xl:rounded-2xl',
                                srcset: `/images/logo/empty-cart-sm.webp 480w,
                                         /images/logo/empty-cart-md.webp 800w,
                                         /images/logo/empty-cart-lg.webp 1200w,
                                         /images/logo/empty-cart-xl.webp 3600w`,
                                sizes: `(max-width: 480px) 100vw,
                                            (max-width: 800px) 50vw,
                                            (max-width: 1200px) 33vw,
                                            (max-width: 2400px) 25vw,
                                            (max-width: 3200px) 20vw,
                                            16vw`,
                                width: '3600',  // Dimensions de l'image la plus grande
                                height: '3600'
                                }"
                            />
                        </div>
                    </section>
                </template>
            </template>
            <!-- empty item -->
            <template v-else>
                <div
                    class="lg:col-span-12 h-[10rem] flex items-center"
                >
                    <loading class="w-6 h-6 lg:w-10 mx-auto" />
                </div>
            </template>

        </div>
    </div>
</template>

