<template>
        <div class="w-[10rem] lg:w-[20rem]">
            <photos-square-carousel
                :Photos="product.photos"
                :currentLocale="locale"
                :showPagination="false"
            />
        </div>
        <div class="w-full ml-4">
            <h3 class="text-sm">{{ product.description.designation }}</h3>
            <div class="w-full flex justify-between items-center">
                <div class="w-full">
                    <select
                        :id="`quantity-${productIdx}`"
                        :name="`quantity-${productIdx}`"
                        class=" rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5
                                        text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        v-model="quantity"
                        @change="handleUpdateQuantity(product.id)"
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                <div class="w-full text-sm lg:text-xl font-open-sans-bold text-gray-900
                            text-right flex justify-end gap-2"
                >
                    <div
                        v-if="product.pre_order"
                        class="w-10"
                    >
                        <ImgLoading
                            :AttributeImg="{
                                    source:`/images/preorder/preorder.webp`,
                                    altText:$t('items.alt'),
                                    titleText:$t('items.title'),
                                    className:'w-full',
                                             srcset: `/images/pageSport/preorder-sm.webp 480w,
                                                    /images/preorder/preorder-md.webp 800w,
                                                    /images/preorder/preorder-lg.webp 1200w,
                                                    /images/preorder/preorder-xl.webp 2400w,
                                                    /images/preorder/preorder.webp 3600w`,
                                    sizes: `(max-width: 480px) 100vw,
                                                            (max-width: 800px) 50vw,
                                                            (max-width: 1200px) 33vw,
                                                            (max-width: 2400px) 25vw,
                                                            (max-width: 3200px) 20vw,
                                                            16vw`,
                                    width: '3600',  // Dimensions de l'image la plus grande
                                    height: '3600'
                                }"
                        />
                    </div>
                    <template v-if="totalAmount>0" >{{ totalAmount.toLocaleString() }} €</template>
                    <div>
                        <button
                            @click="handleRemove(product.id)"
                            type="button"
                            class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Remove</span>
                            <font-awesome-icon :icon="faTrash" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-full text-sm text-gray-400">
                {{product.description.summary}}
            </div>
            <div
                v-if="false"
                class="mt-1 flex text-sm">
                <p class="text-gray-500">{{ product.color }}</p>
                <p v-if="product.size" class="ml-4 border-l border-gray-200 pl-4 text-gray-500">{{ product.size }}</p>
            </div>

            <div v-if="product.reference">
                <div class="">
                    <h3 class="text-sm">
                        {{$t('productSheet.reference')}}
                    </h3>
                    <p class="mt-1 text-sm lg:text-xl font-open-sans-bold text-gray-900">
                        {{ product.reference }}
                    </p>
                </div>
            </div>
            <p
                v-if="false"
                class="mt-4 flex space-x-2 text-sm text-gray-700">
                <CheckIcon v-if="product.inStock" class="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                <ClockIcon v-else class="h-5 w-5 flex-shrink-0 text-gray-300" aria-hidden="true" />
                <span>{{ product.inStock ? 'In stock' : `Ships in ${product.leadTime}` }}</span>
            </p>
        </div>
</template>
<script setup>
    import {CheckIcon, ClockIcon} from "@heroicons/vue/20/solid";
    import { useI18n } from 'vue-i18n';
    import {computed, ref} from "vue";
    import PhotosSquareCarousel from "@/Components/PhotosSquareCarousel.vue";
    import {faTrash} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    //import Preorder from "@/Components/Preorder.vue";
    import ImgLoading from "@/Components/ImgLoading.vue";
    const props = defineProps({
        product: { type: Object },
        productIdx: { type: Number },
    });

    const { locale }       = useI18n();
    const loading          = ref(false);
    const showConfirmation = ref(false);
    const quantity=ref(props.product.quantity);

    const emit= defineEmits(["emitRemove","emitUpdateQuantity"]);

    const description   = ref({});
    function handleRemove(e){
        loading.value=true;
        if (window._paq) {
            window._paq.push(['trackEvent', 'Panier', 'Supprimer',props.product.description.designation]);
        }
        emit('emitRemove',e);
    }
    function handleUpdateQuantity(e){
        emit('emitUpdateQuantity',{id:e,qt:quantity.value});
    }
    function getDescriptions() {
        if (props.product.descriptions && Array.isArray(props.product.descriptions)) {
            description.value = props.product.descriptions.find(descr => {
                // Extraire la partie avant le _
                const langPrefix = descr.lang.split('_')[0];
                return langPrefix === locale.value;
            }) || {}; // Assure qu'on a une valeur par défaut si aucun résultat trouvé
        }
    }

    const totalAmount = computed(() => {

        const totalHT = props.product.price * quantity.value;

        const totalTaxes = props.product.taxes.reduce((acc, tax) => {
            const taxAmount = (totalHT * tax.value) / 100;
            return acc + taxAmount;
        }, 0);


        const tttc = totalHT + totalTaxes;

        return parseFloat(tttc.toFixed(2));

    });
    getDescriptions();
</script>